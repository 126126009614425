import React, { useRef } from "react"
import AllNoticies from "../components/AllNoticies"
import Cercador from "../components/Cercador"
import Layout from "../components/Layout"
import SEO from "../components/seo"
const Noticies = () => {
  return (
    <Layout>
      <SEO
        title={`Notícies`}
        lang="ca"
        keywords={[
          "noticies",
          "blog",
          "blog de les males herbes",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          "narrativa",
          "català",
          "catalana",
          "autor",
          "autora",
          "escriptora",
        ]}
      />
      <div className="flex flex-wrap">
        <div className="w-full">
          <p className="font-title font-bold uppercase text-gray-900 text-xl border-b border-black">
            Notícies
          </p>
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-full ">
          <div class="w-full h-auto">{<AllNoticies />}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Noticies
