import React from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import slugify from "slugify"
import Image from "gatsby-image"

const Noticia = ({ title, thumbnail, excerpt, date }) => {
  const styleProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <animated.div style={styleProps}>
      <Link to={`/noticies/${slugify(title, { lower: true })}`}>
        <Image
          fluid={thumbnail.sharp.fluid}
          className="h-auto"
          alt={`Noticia | ${title} | Editorial Males Herbes`}
        />
      </Link>

      <div className="py-3">
        <div className="font-title text-myblack text-sm font-medium">
          <p>{date}</p>
        </div>
        <div className="text-myblack text-base mt-1">
          <Link to={`/noticies/${slugify(title, { lower: true })}`}>
            <p className="font-title antialiased font-bold text-xl truncate hover:underline">
              {title}
            </p>
          </Link>
        </div>
        <div className="text-myblack text-base mt-1">
          <p className="font-title antialiased text-base">{excerpt}</p>
        </div>
      </div>
    </animated.div>
  )
}

export default Noticia
