import React, { useState } from "react"
import { useStore } from "../store"
import useNoticies from "../hooks/useNoticies"
import Noticia from "./Noticia"

const AllNoticies = () => {
  const books = useNoticies()

  const { searchName, sortDesc, tag } = useStore(state => state)
  const [pagination, setPagination] = useState(0)

  const renderBooks = books => {
    return {
      leng: books.length,
      result: books.slice(pagination, pagination * 8 + 8).map(book => {
        return (
          <div
            key={book.title}
            className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/4 px-4"
          >
            <Noticia {...book} cataleg={true} />
          </div>
        )
      }),
    }
  }

  return (
    <>
      <div className="flex flex-wrap -mx-4 mt-12">
        {renderBooks(books).result}
      </div>
      {renderBooks(books).result.length === 8 && (
        <ul class="flex list-reset rounded w-auto font-title mt-4">
          <li>
            <p class="block bg-pagination text-white rounded-md h-8 w-8 px-3 py-1">
              1
            </p>
          </li>
          <li>
            <p class="block text-pagination border border-pagination h-8 w-8 rounded-md px-3 py-1 ml-1">
              2
            </p>
          </li>
          <li>
            <p class="block text-pagination border border-pagination h-8 w-8 rounded-md px-3 py-1 ml-1">
              3
            </p>
          </li>
          <li>
            <p class="block text-pagination border border-pagination h-8 w-8 rounded-md px-3 py-1 ml-1">
              ...
            </p>
          </li>
          <li>
            <p class="block text-pagination border border-pagination h-8 w-8 rounded-md px-2 py-1 ml-1">
              {Math.ceil(renderBooks(books).leng / 9)}
            </p>
          </li>
        </ul>
      )}
    </>
  )
}

export default AllNoticies
