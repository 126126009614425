import { graphql, useStaticQuery } from "gatsby"

const useNoticies = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              sourceName
            }
            frontmatter {
              title
              date(locale: "ca", formatString: "LL")
              images {
                sharp: childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allMarkdownRemark.edges
    .filter(({ node }) => node.fields.sourceName === "noticies")
    .map(({ node }) => ({
      title: node.frontmatter.title,
      excerpt: node.excerpt,
      date: node.frontmatter.date,
      thumbnail: node.frontmatter.images,
    }))
}

export default useNoticies
